<template>
  <div style="width:100%">
      
    <StudentList v-if="editType == 'list'" @changeShowType="changeShowType"></StudentList>

    <StudentForm v-if="editType != 'list'" :editId="editId"  :editType="editType" @changeShowType="changeShowType"></StudentForm>
      
  </div>
</template>

<script>
import StudentList from './components/StudentList'
import StudentForm from './components/StudentForm'
import {  } from '@/api/index.js'
export default {
  name: 'student',
  components: {  StudentList,StudentForm},
  data() {
    return {
      editType:'list',
      editId:'',
    }
    
  },
  created() {
  },
  methods: {
    changeShowType(type,id){
      if(type=='edit'){
        this.editId = id;
      }
      this.editType = type;
    }
  }
}
</script>

<style>
</style>