<template>
  <div style="width:100%">
    <div class="search-box">
      <el-form inline size="small">
        <el-form-item label="模糊搜索">
          <el-input v-model="form.search" placeholder="姓名"></el-input>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" icon="el-icon-search" @click="handleCurrentChange(1)">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="list" style="max-width: 100%;margin-top:15px;box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);">
      <el-table-column type="index" align="center" label="序号" width="50"></el-table-column>
      <el-table-column label="地区" align="center" show-overflow-tooltip >
        <template slot-scope="scope">
          <span >{{scope.row.proName+","+scope.row.cityName}}</span><br />
          <span >{{scope.row.regName+","+scope.row.streetName}}</span>
        </template>
      </el-table-column>
      <el-table-column label="学校" align="center" show-overflow-tooltip prop="schoolName" ></el-table-column>  
      <el-table-column label="姓名" align="center" prop="name" width="180"></el-table-column> 
      
      <el-table-column label="年级" align="center" prop="gradeName" width="180"></el-table-column> 
      <el-table-column label="班级" align="center" prop="className" width="180"></el-table-column>   
      <el-table-column label="手机号" align="center" prop="phone" width="180"></el-table-column> 
    </el-table>
    <el-pagination layout="total, prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="form.pageSize" :current-page="form.pageNum" :total="total"></el-pagination>


  </div>
</template>

<script>
import { Confirm } from '@/utils/common.js'
import { getStudenList } from '@/api/index.js'
export default {
  name: 'studentList',
  components: {  },
  data() {
    return {
      showType: 0,
      editType: '',
      isAddDialog:false,
      isFastAddDialog:false,
      form: {
        pageNum: 1,          //页码
        pageSize: 10,          //每页条数
        search: '',          //搜索条件
      },
      list: [],
      total: 0,
      editType: ''
    }
    
  },
  created() {
    this.getList();
  },
  methods: {
    goSetGrade(schoolTypeInfo){
      this.$emit('changeShowType', 'grade',schoolTypeInfo)
    },
    editDetail(id, editType) {
      this.$emit('changeShowType', editType,id)
    },
    getList() {
      getStudenList(this.form).then(res => {
        if (res.code != 200) return
        this.list = res.data.records
        this.total = res.data.total
      })
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.handleCurrentChange(1)
    },
    handleCurrentChange(val) {
      this.form.pageNum = val
      this.getList()
    },
  }
}
</script>

<style>
</style>